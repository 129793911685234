ul.form-collection {
  list-style: none;
  padding: 0;

  li {
    padding: 10px;
    background: #f2f2f2;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}

ul.pagination {
  padding: 0 0 0 15px !important;
}

td ol {
  -webkit-padding-start: 20px !important;
}

td ul {
  -webkit-padding-start: 20px !important;
}

.submenu {
  padding: 0 0 0 15px !important;
  margin-left: 60px !important;
}

textarea {
  resize: none;
}

.table {
  > tbody > tr > th {
    border-bottom-width: 1px;
    font-size: $font-size-small;
    text-transform: uppercase;
    color: $dark-gray;
    font-weight: $font-weight-normal;
    padding-bottom: 5px;
  }
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.textedit-wrapper {
  width: 100% !important;
  display: block !important;
}

.textedit-editor {
  min-height: 65vh !important;
  max-height: 65vh !important;
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.rdw-save-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}

.textedit-toolbar,
.textedit-toolbar * {
  box-sizing: content-box;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}